.about {
    width: 100%;
    color: whitesmoke;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    overflow-x: hidden;
    background-color: #202731;
}

.title {
    color: #F8F0E3;
    font-size: 3.5em;
    text-align: center;
    margin-top: 1%;
    margin-bottom: 1%;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.blue, .orange {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 20em;
    padding: 11em 20vw;
}


/* Section 1 */
.blue {
    padding-top: 1em;
}

section .section-1 {
    margin-top: 0;
}

.section-1 {
    font-size: 1.7em;
    line-height: 1.7em;
    width: 100%;
    float: left;
    margin-top: 0;
}

.section-1 img {
    width: 40%;
    margin-left: 10%;
    float: right;
    overflow: hidden;
    border-radius: 2px;
    box-shadow: 6px -6px 2px 1px rgba(102, 85, 85, 0.5);
}


/* Curve */
.curve {
    position: absolute;
    height: 14em;
    width: 100%;
    bottom: 0;
}

.curve::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 20%;
    width: 60%;
    height: 100%;
    background-color: #ad4009;
    transform: translate(85%, 60%);
}

.curve::after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 48%;
    width: 65%;
    height: 99%;
    background-color: #202731;
    transform: translate(-4%, 40%);
    z-index: 1;
}


/* Section 2 */
.orange {
    background-color: #ad4009;
}

.orange .section-2 {
    margin-top: 2em;
    width: 100%;
}

.section-2 img {
    width: 40%;
    float: left;
    margin-right: 10%;
    border-radius: 2px;
    box-shadow: -6px -6px 2px 1px rgba(32, 39, 49, 0.3);
}

.section-2 {
    font-size: 1.5em;
    width: 50%;
    float: right;
    margin-top: 0;
    margin-left: 5%;
}


@media (max-width: 1024px) {
    .title {
        font-size: 2em;
        margin-bottom: .3em;
    }

    .blue, .orange {
        padding: 1em 5vw 10em;
    }

    .section-1, .section-2 {
        font-size: 1.3em;
        line-height: normal;
    }

    .section-1 img {
        width: 40%;
        margin-left: .5rem;
        float: right;
        overflow: hidden;
        border-radius: 15px;
    }

    .orange .section-2 {
        margin-top: 6em;
    }

    .section-2 img {
        border-radius: 25%;
        shape-outside: ellipse();
        shape-margin: 1rem;
    }

}