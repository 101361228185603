.contact {
    background-image: url("../assets/guitar.jpg");
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    background-position: center;
    transform-origin: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

/* Overall Form-Wrapper & Contact Form */
.form-wrapper {
    max-width: 450px;
    margin-left: 10%;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 7px;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, .4);
    backdrop-filter: blur(4px);
}

.form-wrapper h1 {
    color: whitesmoke;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.contact-form {
    margin: 5%;
    margin-bottom: 10%;
}


/* Responsive */
@media (max-width: 1030px) {
    .contact {
        height: 100vh;
    }

    .form-wrapper {
        max-width: 70%;
        margin: 5% 15% 5% 15%;
    }
}

@media (max-width: 770px) {
    .contact {
        height: 77vh;
    }

    .form-wrapper {
        max-width: 80%;
        margin-top: 0;
        margin-bottom: 0;
    }
}

@media (max-width: 740px) {
    .contact {
        height: 170vh;
    }
}

@media (max-width: 680px) {
    .contact {
        height: 200vh;
    }
}

@media (max-width: 430px) {
    .contact {
        height: 100vh;
    }
}

