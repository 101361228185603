.footer {
  width: 100%;
  height: 175px;
  background-color: #121619;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
}

.social-media svg {
  cursor: pointer;
  color: white;
  margin: 20px;
  font-size: 80px;
  transition: all 150ms ease;
  -webkit-transition: all 150ms ease;
}

.social-media svg:hover {
  color: burlywood;
}

.footer p {
  color: white;
  padding-bottom: 10px;
  text-align: center;
}

@media (max-width: 430px) {
  .social-media svg {
    cursor: pointer;
    color: white;
    margin: 20px;
    font-size: 60px;
  }

  .footer p {
    font-size: small;
    padding-bottom: 10px;
    text-align: center;
  }
}