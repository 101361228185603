.navbar {
    width: 100%;
    height: 100px;
    background-color: #121619;
    display: flex;
    flex-direction: row;

    position: sticky;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, .4);
    top: 0;
    z-index: 100;
}


/***** Nav Bar Logo *****/
.navbar .left-side {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 150px;
}

.navbar .left-side img {
    width: 70px;
}


/***** Nav Bar Page Links *****/
.navbar .right-side {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar a {
    color: white;
    text-decoration: none;
    margin: 20px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.navbar a:hover {
    color: burlywood;
}


/* Hide dropdown menu until it's time */
.dropdown-component {
    display: none;
}


/* Responsiveness */
@media (max-width: 1030px) {
    .navbar .left-side {
        padding-left: 1%;
    }
    
    .navbar .right-side {
        flex: 0;
        margin-right: 5%;
    }
}

/*
• Commented lines remove the drop down menu
  and replace with plain text menu items instead
• Removed gallery page link as it's not conducive
  when on mobile
*/

/* Medium */
@media (max-width: 420px) {
    .navbar .left-side {
        display: none;
    }

    .navbar .right-side .dropdown-component {
        /* display: block !important; */
        margin-right: 5%;
        z-index: 999;
    }

    .navbar .right-side {
        text-align: center;
    }

    .navbar .right-side .linky-links {
        /* display: none; */
        /* margin: 0; */
        font-size: 1;
    }

    .navbar .right-side .linky-links .gallery {
        display: none;
    }
}

@supports (-webkit-touch-callout: none) {
    @media (max-width: 420px) {
        .navbar .left-side {
            display: none;
        }

        .dropdown-component {
            display: none;
        }

        .navbar .right-side .linky-links {
            margin: auto;
            font-size: 1;
        }
    }
}
