.music-background {
    background-image: url("../assets/GS_Scene.jpeg");
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-position: center 35%;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.bandcamp {
    margin: 2% auto 2%;
    column-count: 2;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-width: 50%;
    -webkit-column-width: 50%;
    -moz-column-width: 50%;
}

.wolves {
    text-align: right;
    margin-right: 5%;
}

.good-stuff {
    text-align: left;
    margin-left: 5%;
}

iframe {
    border-width: 2px;
    width: 350px;
    height: 621px;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, .7);
}


/* Responsiveness */
@media (max-width: 1030px) {
    .bandcamp {
        margin-top: 1%;
        width: 100%;
        column-count: 2;
        -webkit-column-count: 2;
        -moz-column-count: 2;
    }

    iframe {
        border-width: 1px;
        width: 300px;
        height: 574px;
    }
}

/* 2 columns but smaller iFrames */
@media (max-width: 690px) {
    iframe {
        border-width: 1px;
        width: 275px;
        height: 500px;
    }
}

/* Phone portrait & 1 column */
@media (max-width: 660px) {
    .bandcamp {
        margin: 0 auto 0 auto;
        width: 90%;
        column-count: 1;
        -webkit-column-count: 1;
        -moz-column-count: 1;
        padding: 0;
    }

    .wolves {
        text-align: center;
        margin: 2% auto 1% auto;
    }
    
    .good-stuff {
        text-align: center;
        margin: 0% auto 2% auto;
    }

    iframe {
        border-width: 1px;
        width: 275px;
        height: 500px;
    }
}