/* Actual Contact Form */
label h3 {
    text-align: left;
    display: block;
    padding-top: 3px;
    font-weight: bold;
    color: whitesmoke;
    text-shadow: -.5px -1px 0 black, .5px -.5px 0 black, -.5px .5px 0 black, .5px .5px 0 black;
    margin-bottom: 0;
}

input, textarea, select {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ddd;
    border-radius: 7px;
    box-sizing: border-box;
    display: block;
}

input.button {
    background: #1b9b90;
    color: #fff;
    font-weight: 900;
    border: 1px;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 14px;
    transition: all 200ms ease;
    -webkit-transition: all 200ms ease;
    width: 100%;
}

input.button:hover {
    background: #136e67;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, .3);
}

input.button:focus {
    background: #136e67;
    /* box-shadow: 1px 3px 5px rgba(0, 0, 0, .3); */
    box-shadow: 0px 1px 3px rgba(0, 0, 0, .1);
    margin-top: 15px;
    animation: animate-button .3s forwards;
}


/* Responsive */
@media (max-width: 740px) {    
    label h3 {
        font-size: medium;
    }

    input, textarea, select {
        padding: 5px;
        margin: 5px 0;
    }
}

@media (max-width: 430px) {
    input, textarea, select {
        padding: 10px 10px;
        margin: 5px 0;
    }
}