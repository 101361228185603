.home {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  background-position: center 35%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 35%;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.logo {
  visibility: hidden;
  width: 95%;
  margin: auto;
}

.logo img {
  width: 100%;
}


@media (max-width: 1030px) {
  .logo {
    visibility: hidden;
  }
}

@media (max-width: 420px) {
  .logo {
    visibility: visible;
    width: 80%;
    margin: 10% auto auto auto;
  }
}