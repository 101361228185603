.up-arrow {
    position: fixed;
    bottom: 10px;
    right: 25px;
    height: 50px;
    width: 50px;
}

.up-arrow svg {
    font-size: 50px;
    color: whitesmoke;
    border-radius: 4px;
    border: 0px;
    background-color: rgba(32, 39, 49, 0.5);
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.up-arrow svg:hover {
    background-color: rgba(32, 39, 49, .9);
}


@media (max-width: 1030px) {
    .up-arrow {
        bottom: 20px;
        right: 20px;
        height: 40px;
        width: 40px;

        visibility: hidden;
    }

    .up-arrow svg {
        font-size: 40px;
    }
}