.dropdown {
    position: relative;
}

.dropdown .menu-button {
    color: white;
    cursor: pointer;
    background-color: transparent;
    border: none;
    transition: all 150ms ease;
    -webkit-transition: all 150ms ease;
}

.dropdown .menu-button svg {
    font-size: 40px;
}

.menu-button:hover {
    color: burlywood;
}

/* Menu */
.dropdown-menu {
    position: absolute !important;
    right: 0;
    background-color: #121619;
    padding: 1rem 0 1rem 0;
    border-radius: .25rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .2);
    opacity: 0;
    margin: 0;
    z-index: 999;
    -webkit-transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
    transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
}

.dropdown .link {
    font-size: x-large;
    text-align: right;
    margin: 5px auto 5px auto;
    list-style-type: none;
}

.dropdown-menu a {
    color: white;
    text-decoration: none;
    -webkit-transition: 150ms ease;
    transition: 150ms ease;
}

.dropdown-menu a:hover {
    color: burlywood;
    text-decoration: none;
}


/* Dropdown */
.dropdown > .menu-button:focus + .dropdown-menu {
    opacity: 1;
    pointer-events: auto;
}