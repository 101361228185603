.shows {
    background-image: url("../assets/shows_bg.JPG");
    width: 100%;
    height: 90vh;
    justify-content: center;
    flex-direction: column;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}


/* Shows Page Title */
.page-title {
    color: #F8F0E3;
    font-size: 5em;
    text-align: center;
    margin-top: 1%;
    margin-bottom: 0px;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}


/* Table Container */
.shows-table {
    width: 65%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 2em;
    margin-bottom: 1em;

    border: 1px solid rgba(0, 0, 0, .7);
    border-radius: 15px;
    box-shadow: 0 5px 14px 13px rgba(0, 0, 0, .4);
    backdrop-filter: blur(10px);
}


/* Shows List 'section' Rows */
.circle {
    float: left;
}

/* Date Circle */
.circle .date {
    background-color: #F8F0E3;
    font-weight: bold;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100px;
    height: 100px;
    border: 3px solid #191817;
    border-radius: 50%;
    margin-right: 15px;
}

.date p {
    margin: auto;
    font-size: x-large;
}


/* No Shows */
.no-show {
    color: #aca091;
    font-size: 40px;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}


/* Venue & Location */
.venue {
    float: left;
}

.venue h1, h4 {
    color: #aca091;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.venue h1 {
    color: #aca091;
    font-size: 40px;
    margin-bottom: 0px;
    margin-top: 3%;
}

.venue h4 {
    color: #F8F0E3;
    font-size: 20px;
    float: left;
    margin-top: 0px;
}


/* Buttons */
.buttons {
    float: right;
    margin-top: 3%;
}

.info {
    border-color: #F8F0E3;
    background-color: transparent;
    transition: all 200ms ease;
    -webkit-transition: all 200ms ease;
}

.info:hover {
    background-color: #aca091;
    border-color: #aca091;
}

.buttons a {
    color: #F8F0E3;
    font-weight: bold;
    font-size: 30px;
    text-decoration: none;
    transition: all 100ms ease;
    -webkit-transition: all 100ms ease;
}

.buttons a:hover {
    color: #000;
    text-decoration: none;
}


/* Divider */
hr {
    color: #aca091;
    display: block;
    margin-bottom: 2%;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
    width: 100%;
}


/* Responsiveness */
@media (max-width: 1030px) {
    .shows {
        height: 100vh;
    }

    .page-title {
        font-size: 3em;
    }

    .shows-table {
        width: 65%;
        margin-bottom: 1em;
    }

    .no-show {
        font-size: 20px;
    }
}


@media (max-width: 775px) {
    .venue h1 {
        font-size: ;
        margin-top: .2em;
        margin-bottom: 0px;
    }
    
    .buttons {
        float: left;
        margin: -10px auto 4% 28%;
    }

    .buttons a {
        font-size: 1.5em;
    }
}


@media (max-width: 420px) {
    /* Date Circle */
    .circle {
        margin-top: 5%;
        shape-outside: circle();
    }

    .circle .date {
        width: 70px;
        height: 70px;
        margin-right: 10px;
    }

    .date p {
        font-size: large;
    }


    /* Venue & Location */
    .venue h1 {
        font-size: 1.1em;
        margin-top: 10%;
        margin-bottom: 0px;
    }

    .venue h4 {
        font-size: 15px;
        float: left;
        margin-top: 0px;
    }

    .buttons {
        margin: -10px auto 6% 33%;
    }

    .buttons a {
        font-size: 1em;
    }
}

@media (max-width: 380px) {
    .venue h1 {
        font-size: 1em;
        margin-top: 13%;
    }

    .buttons {
        margin-left: 37%;
    }
}